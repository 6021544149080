import { reduce, isNil } from 'ramda';
import theme from '@greenberry/salal/theme';
import formatYear from '../../../utils/formatYear';
import sortByYear from '../../../utils/sortByYear';
import {
  categoriesParticipationRate,
  categoriesStudentCount,
} from '../../../constants/studentCategories';

export const formatParticipationRate = (indicator, national, sector) => {
  const data = reduce(
    (acc, _year) => {
      const year = formatYear(_year);
      const formsInSector = categoriesParticipationRate[sector];
      acc[year] = formsInSector.map(({ key, label }) => ({
        label,
        values: [
          {
            value: isNil(indicator[_year][key]) ? 0 : indicator[_year][key],
          },
          {
            value: isNil(national[_year]) ? 0 : national[_year][key],
            striped: true,
            opacity: 0.8,
            color: theme.color('chart.9'),
          },
        ],
      }));

      return acc;
    },
    {},
    sortByYear(Object.keys(indicator))
  );

  return data;
};

export const formatStudentCount = (indicator, sector) => {
  const data = reduce(
    (acc, _year) => {
      const formsInSector = categoriesStudentCount[sector];
      const year = formatYear(_year);
      acc[year] = formsInSector.map(({ label, key }, index) => {
        if (isNil(indicator[_year][key])) return null;
        return {
          label,
          values: [
            {
              value: isNil(indicator[_year][key]) ? 0 : indicator[_year][key],
              color: theme.color(`chart.${index + 1}`),
            },
          ],
        };
      });

      return acc;
    },
    {},
    sortByYear(Object.keys(indicator))
  );

  return data;
};
